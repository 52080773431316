@import "src/styles/mixins";
@import "src/styles/mixins/buttons";
@import "src/styles/zindexes";

.overlay {
  @include overlay;

  z-index: $zindexBlogNewsletterPlaceholder;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  width: 100%;

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 50%;
    border: 1px solid var(--main-blue);
    margin-bottom: 13px;
  }

  .icon {
    width: 30px;
    height: 30px;
    color: var(--main-blue);
    margin-left: auto;
    margin-right: auto;
  }

  .title {
    margin-bottom: 8px;
    white-space: break-spaces;
    text-align: center;
  }

  .description {
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    white-space: break-spaces;
  }

  .button {
    padding: 12px 30px;
    width: fit-content;
    @include basic-outlined-button;

    p {
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
    }
  }
}
