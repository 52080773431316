.wrapper {
  width: 100%;
  height: 100%;

  .image {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
