.description {
  margin-bottom: 45px;
  text-align: center;

  p {
    display: inline;
    white-space: break-spaces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  a {
    display: block;
    margin-left: 4px;
    color: var(--main-blue);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}
