@import "~modern-css-reset";
@import "colors";
@import "animations";
@import "fonts";
@import "breakpoints";
@import "mixins";
@import "ncolors";
@import "integration/home_depot_colors";

:root {
  --roboto-font: "Roboto", helvetica, arial, sans-serif;
  --urbanist-font: "Urbanist", helvetica, arial, sans-serif;
}

html {
  overscroll-behavior-y: none;
}

html[welcometour="true"] {
  overflow: auto;
}

html[data-scroll-hidden="true"] {
  overflow: hidden;
}

body {
  --color-main-blue: var(--main-blue);
  --image-border-radius: 4px;

  color: var(--main-black);
  font-family: var(--roboto-font);
  letter-spacing: 0;
  @include dvh(height, 100);

  // Note(pavel): overriding min-height from css-reset
  @include dvh(min-height, 100);

  min-width: $minScreenWidth;
}

.placeholderCenter {
  ::placeholder {
    text-align: center;
  }
}

.transition {
  opacity: 1 !important;
  transition: opacity 1s;
}

.error {
  color: var(--color-error-main) !important;
}

@media print {
  html,
  body {
    height: auto;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
