@import "src/styles/mixins";
@import "src/styles/zindexes";

.backdrop {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: $zindexBackdrop;
  background-color: var(--color-overlay-disabled);
  backdrop-filter: none;
  animation: 0.5s show ease-in-out forwards;
}

.snackbarWrapper {
  padding: rem(6px) rem(16px) rem(6px) rem(4px);
  background-color: var(--color-snack-bar-background-dark);
  gap: 25px;
}

.actionButton {
  padding: rem(12px) rem(8px);
}

.messageContainer {
  height: 56px;
  padding: 8px 0;
}

.titleText {
  font-size: rem(14px);
  font-weight: 700;
  line-height: 143%;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
