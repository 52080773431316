@import "src/styles/mixins";

.container {
  p {
    padding-left: 12px;
    padding-right: 0;
  }
}

.messageContainer {
  padding: 0;
  height: auto;
}

.subtitleContainer {
  padding-left: 12px;

  @include mobile-only {
    max-width: rem(298px);
  }

  span {
    font-weight: 400;
    font-size: rem(14px);
    line-height: rem(20px);
  }
}

.closeButton path {
  fill: var(--color-white);
  fill-opacity: 1;
}

.title {
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 16px;
}

.info {
  background-color: var(--color-grey100);

  p {
    color: var(--main-black);
  }
}

.infoBlue {
  background-color: var(--color-info-main);

  svg path {
    fill: var(--color-white);
    fill-opacity: 1;
  }

  p {
    color: var(--color-white);
  }
}

.infoAlert {
  background-color: var(--color-info-main);
  color: var(--color-white);

  svg path {
    fill: var(--color-white);
    fill-opacity: 1;
  }

  p {
    color: var(--color-white);
  }
}

.success {
  background-color: var(--color-success-main);

  svg {
    color: var(--color-white);

    path {
      fill: var(--color-white);
      fill-opacity: 1;
    }
  }

  p {
    color: var(--color-white);
  }
}

.alert {
  background-color: var(--color-error-main);
  color: var(--color-white);

  .title {
    font-weight: 400;
  }

  p {
    color: var(--color-white);
  }

  svg {
    max-width: 22px;
    max-height: 22px;
  }
}

.warning {
  background-color: var(--color-warning-main);
  color: var(--color-white);

  .title {
    font-weight: 400;
  }

  p {
    color: var(--color-white);
  }

  svg {
    max-width: 22px;
    max-height: 22px;
  }
}

.standard {
  padding: 14px 16px;

  svg {
    width: 22px;
    height: 22px;
  }
}
