@import "src/styles/mixins.scss";

.wrapper {
  width: 100%;
  height: vh(100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-grey100);
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;

  .logo {
    width: 80px;
    height: 80px;
    margin-bottom: 30px;
  }

  .loader {
    width: 200px;
    border-radius: 100px;
    margin-bottom: 24px;
    background-color: var(--color-elevation1);
  }

  .loaderBar {
    background-color: var(--main-black);
  }

  .text {
    color: var(--pure-black);
  }
}

.slideUp {
  animation: slide-up 500ms ease-in-out forwards;
}

.slideDown {
  animation: slide-down 500ms ease-in-out forwards;
}

.slideUpCaption {
  animation: slide-up 250ms ease-in-out forwards;
  animation-delay: 500ms;
}

.fadeIn {
  animation: fade-in 500ms ease-in-out forwards;
}

.fadeOut {
  animation: fade-out 500ms ease-in-out forwards;
}

.initial {
  opacity: 0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(20%);
    opacity: 0;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(20%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
