@import "src/styles/zindexes";
@import "src/styles/mixins";

.content {
  --width: 390px;
  --padding: 24px;

  width: var(--width);
  padding: var(--padding);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  position: relative;
}

.header {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: $zindexSidebarClose;

  .close {
    padding: 0;

    svg {
      color: var(--main-black);
      width: 24px;
      height: 24px;
    }
  }
}

.backdrop {
  backdrop-filter: none;
  background: var(--color-black-opacity50);
}

.paper {
  height: 100%;
}

@include xs-screen {
  .content {
    --width: 100%;
    --padding: 15px;
  }

  .header {
    .close {
      border-radius: 50%;
      border: 1px solid var(--color-grey300);
      padding: 9px;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}
