@import "src/styles/mixins";

.snackbarWrapper {
  padding: rem(6px) rem(16px) rem(6px) rem(4px);
  background-color: var(--color-snack-bar-background-dark);
  border-radius: rem(4px);
  gap: 13px;
}

.actionButton {
  padding: rem(12px) rem(8px);
}

.messageContainer {
  height: 56px;
  padding: 8px 0;
}

.messageText {
  font-size: rem(14px);
  font-weight: 400;
  line-height: 143%;
}
