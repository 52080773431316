// TODO: We need to pick valid breakpoints and remove others to avoid confusion
$xs: 600px;
$sm: 900px;
$md: 1200px;
$lg: 1536px;
$mobile: 30rem;
$tablet: 75rem;
$desktop: 120rem;
$xsWidth: 0;
$smWidth: 1024;
$mdWidth: 1280;
$lgWidth: 1680;
$xlWidth: 2560;
$minScreenWidth: 320px;
