.placeholder {
  position: relative;
  width: 100%;
  height: 100%;

  &__icon {
    display: flex;
    color: var(--color-action-disabled);

    &.absolute {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-grey200);
  }

  &__loadingWrapper {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.hideImage {
  visibility: hidden;
}
