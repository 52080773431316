@import "src/styles/mixins";

// TODO: We should use basic buttons instead and customize them via material ui
@mixin basic-outlined-button() {
  border: 1px solid var(--color-grey300);

  @include true-hover {
    background: var(--color-action-hover);
    border-color: var(--color-grey300);
  }
}

@mixin basic-contained-button() {
  p {
    color: var(--color-white);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
}

@mixin basic-text-button($color: var(--color-grey300)) {
  padding: 8px 5px;
  border-radius: 4px;

  @include true-hover {
    background: $color;
  }
}

@mixin unstyled-section-button() {
  padding: 9px 5px;

  @include true-hover {
    background: var(--color-button-primary-hover-background);
  }
}
