@use "sass:math";
@import "breakpoints";
@import "src/styles/mixins/shadows";

@mixin reset-ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  list-style-type: none;
}

@mixin reset-button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
}

@mixin dvh($property, $value) {
  #{$property}: #{$value}vh;

  /* Note(pavel): Use dvh if it is supported; if not, if'll fallback to vh above */
  #{$property}: #{$value}dvh;
}

// Media query mixin

/*

all the styles are written for desktops, laptops by default
if needs arises laptop-tab mixin is used to add complexities
mobile responsiveness is added by mobile-only mixin

$breakpoint argument

320px - 480px: mobile
480px - 1200px: laptop-tab
1200px + : desktop

usage

@include respond(mobile) {
  css declarations
}

*/

@mixin respond($breakpoint) {
  @media only screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin xs-screen() {
  @media only screen and (min-width: #{$xsWidth}px) and (max-width: #{$smWidth - 1}px) {
    @content;
  }
}

@mixin sm-screen() {
  @media only screen and (min-width: #{$smWidth}px) and (max-width: #{$mdWidth - 1}px) {
    @content;
  }
}

@mixin md-screen() {
  @media only screen and (min-width: #{$mdWidth}px) and (max-width: #{$lgWidth - 1}px) {
    @content;
  }
}

@mixin lg-screen() {
  @media only screen and (min-width: #{$lgWidth}px) {
    @content;
  }
}

@mixin mobile-only() {
  @include respond($mobile) {
    @content;
  }
}

@mixin tablet() {
  @media only screen and (min-width: #{$mobile}) and (max-width: #{$smWidth}px) {
    @content;
  }
}

@mixin laptop() {
  @include respond(#{$lgWidth}px) {
    @content;
  }
}

@mixin laptop-tab() {
  @include respond($tablet) {
    @content;
  }
}

@mixin desktop() {
  @include respond($desktop) {
    @content;
  }
}

@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

@function rem($pxValue) {
  @return #{math.div(strip-unit($pxValue), strip-unit(16))}rem;
}

@function negative($val) {
  @return calc(-1 * $val);
}

/*
uses global variable --vh, which represent the available viewport height
as mobile browsers do not give us the whole screen
browsers add url address bar space and make screen scrollable
which causes issue like centering the items and input scrolls on focus
 */

@function vh($quantity) {
  @return calc(var(--vh, 1vh) * #{$quantity});
}

@mixin flex-gap($gapX, $gapY) {
  display: inline-flex;
  flex-wrap: wrap;
  margin: calc(-1 * #{$gapY}) 0 0 calc(-1 * #{$gapX});
  width: calc(100% + #{$gapX});

  & > * {
    margin: $gapY 0 0 $gapX;
  }
}

/**
*   Applies styles only if the device allows hover (mouse pointer)
*/
@mixin true-hover() {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

/**
*   Applies styles only if it's a touch screen device
*/
@mixin touch-screen-device() {
  @media (hover: none) and (pointer: coarse) {
    @content;
  }
}

@mixin overlay() {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--color-white);
  display: flex;
}
