@import "src/styles/mixins";

.button {
  p {
    color: var(--color-white);
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
  }
}

.inputs {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
