@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: block;
  font-weight: 700;
  src: url("~@fontsource/roboto/files/roboto-latin-700-normal.woff2") format("woff2"), url("~@fontsource/roboto/files/roboto-all-700-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: block;
  font-weight: 500;
  src: url("~@fontsource/roboto/files/roboto-latin-500-normal.woff2") format("woff2"), url("~@fontsource/roboto/files/roboto-all-500-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url("~@fontsource/roboto/files/roboto-latin-400-normal.woff2") format("woff2"), url("~@fontsource/roboto/files/roboto-all-400-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "UrbanistVariable";
  font-style: normal;
  font-display: block;
  font-weight: 100 900;
  src: url("~@fontsource/urbanist/files/urbanist-latin-variable-wghtOnly-normal.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url("~@fontsource/urbanist/files/urbanist-latin-400-normal.woff2") format("woff2"), url("~@fontsource/urbanist/files/urbanist-latin-400-normal.woff") format("woff");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-display: block;
  font-weight: 600;
  src: url("~@fontsource/urbanist/files/urbanist-latin-600-normal.woff2") format("woff2"), url("~@fontsource/urbanist/files/urbanist-latin-600-normal.woff") format("woff");
}
