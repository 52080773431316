.home_depot_theme {
  --main-blue: #ff6600;
  --primary-dark: #662900;
  --color-info-states-contained-hover-background: linear-gradient(
      0deg,
      #0000004d 0%,
      #0000004d 100%
    ),
    #ff9000;
  --color-info-main: #ff9000;
  --color-button-primary: #ff6600;
  --color-button-primary-outlined-border: #ffb280;
  --color-button-primary-outlined-hover: #fff3eb;
  --color-button-primary-hover-background: #fff3eb;
  --color-button-secondary-hover: #fff3eb;
  --color-blue: #ff6600;
  --color-blue1: #fff3eb;
  --color-button-primary-hover-background-default: #bf4d00;
  --color-button-primary-hover: #bf4d00;
}
