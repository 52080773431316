.fadeIn {
  animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fadeInFast {
  animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeOut {
  animation: fade-out 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.slideOutBottom {
  animation: slide-out-bottom 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes slide-out-bottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(100px);
    opacity: 0;
  }
}

.slideInTop {
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slideInRight {
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-right {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slideInLeft {
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slideInFwdBr {
  animation: slide-in-fwd-br 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-fwd-br {
  0% {
    transform: scale(0.3) translateY(90px);
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

.slideInBottom {
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.bounceInRight {
  animation: bounce-in-right 0.35s both;
}

@keyframes bounce-in-right {
  0% {
    max-width: 0;
    margin-right: -55px;
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  40% {
    margin-right: 20px;
  }

  100% {
    max-width: 9000px;
    margin-right: 0;
  }
}

@keyframes slide-in-from-bottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-in-from-top {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.slideInFromBottom {
  animation: slide-in-from-bottom 1s ease-out;
}

.slideInFromTop {
  animation: slide-in-from-top 1s ease-out;
}
