@import "src/styles/mixins";

.actions {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  .button {
    padding: 12px 16px;

    p {
      color: var(--color-white);
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }

    &__cancel {
      display: none;

      p {
        color: var(--main-black);
      }
    }
  }
}

@include xs-screen {
  .actions {
    .button {
      height: 44px;

      &__cancel {
        display: flex;
      }
    }
  }
}
