@import "src/styles/zindexes";

@keyframes placeholder-shimmer {
  100% {
    transform: translateX(100%);
  }
}

.skeleton {
  --skeleton-animation-duration: 3.2s;
  --skeleton-background-color: var(--color-grey-shimmer-bg);
  --skeleton-gradient1-color: var(--color-grey-shimmer1);
  --skeleton-gradient2-color: var(--color-grey-shimmer2);
  --skeleton-gradient3-color: var(--color-grey-shimmer3);

  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: var(--skeleton-background-color);
  z-index: $zindexSkeleton;
  overflow: hidden;

  &::after {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    content: "";
    animation-duration: var(--skeleton-animation-duration);
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholder-shimmer;
    animation-timing-function: linear;
    background-image: linear-gradient(90deg, var(--skeleton-gradient1-color) 0, var(--skeleton-gradient2-color) 20%, var(--skeleton-gradient3-color) 60%, var(--skeleton-gradient1-color));
  }
}
