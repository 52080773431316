@import "src/styles/zindexes";
@import "src/styles/mixins";

.overlay {
  @include overlay;

  z-index: $zindexBlogNewsletterPlaceholder;

  .loading {
    margin: auto;
  }
}
