.tooltip {
  background-color: var(--color-white) !important;
  padding: 4px 8px !important;
}

.title {
  color: var(--color-grey800);
}

.arrow {
  color: var(--color-white) !important;
}
