@import "src/styles/mixins/buttons";
@import "src/styles/mixins";

.wrapper {
  display: flex;
}

.default {
  padding: 0;
}

.outlined {
  --padding: 12px;
  --icon-size: 20px;

  padding: var(--padding);
  border-radius: 4px;
  transition: all 150ms ease-out;

  svg {
    transition: all 150ms ease-out;
    width: var(--icon-size);
    height: var(--icon-size);
  }

  @include basic-outlined-button;
}

.tooltip {
  font-weight: 400;
}

@include sm-screen {
  .outlined {
    --padding: 8px;
    --icon-size: 16px;
  }
}
