.image.lv1 {
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: none;
  }
}

.money1,
.money2,
.money3,
.money4 {
  [data-nomodify="true"] {
    fill: var(--color-white) !important;
  }
}
