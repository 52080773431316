@import "src/styles/zindexes";
@import "src/styles/mixins";

.content {
  .logo {
    width: 84px;
    height: 53px;
    margin-bottom: 16px;
  }

  .title {
    text-align: center;
    margin-bottom: 8px;
  }

  .description {
    text-align: center;
    margin-bottom: 35px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .infoContainer {
    margin-top: 15px;
    display: block;
    text-align: center;
  }

  .info {
    font-size: 12px;
    display: inline;
    line-height: 21px;
  }
}

.input {
  margin-bottom: 24px;
}

@include xs-screen {
  .content {
    .title,
    .description {
      white-space: break-spaces;
    }

    .info {
      line-height: normal;
    }
  }
}
