.content {
  padding: 48px 24px 24px;
  overflow: hidden;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .iconWrapper {
    padding: 10px;
    border-radius: 50%;
    border: 1px solid var(--color-grey300);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
  }

  .icon {
    width: 30px;
    height: 30px;
    color: var(--main-black);
    display: flex;
  }

  .title {
    margin-bottom: 10px;
    text-align: center;
    white-space: break-spaces;
  }

  .description {
    white-space: break-spaces;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 45px;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}
