$zindexMainHeader: 5;
$zindexFooter: 5;
$zindexHIPsDashboardProjectDisabledOverlay: 1;
$zindexHIPsDashboardMediaFilters: 1;
$zindexHIPsDashboardMediaWrapper: 2;
$zindexSidebarClose: 6;
$zindexPropertyCard: 1;
$zindexFooterDrawer: 2;
$zindexMediaEmptyState: 1;
$zindexMediaImageFiltersWrapper: 2;
$zindexMediaLoadingIndicator: 1;
$zindexMediaBuildStatusUploadsHeader: 2;
$zindexMediaFABMobile: 6;
$zindexBackdrop: 10;
$zindexMediaPopper: 1300;
$zindexTourElement: 1302;
$zindexDialog: 1303;
$zindexSOWImage: 1;
$zindexHIPsDashboardActiveBudgetTile: 3;
$zindexDragOverlay: 2;
$zindexRoomCardRemoveButton: 2;
$zindexRoomCardRoomName: 2;
$zindexRoomCardRadiantOverlay: 1;
$zindexEditRoomCardOverlay: 3;
$zindexEditRoomCardOverlayTooltip: 1;
$zindexEditRoomCardOverlayLabel: 2;
$zindexEditRoomModalHeader: 1;
$zindexEditRoomModalActions: 1;
$zindexMediaHeaderHipContainer: 1;
$zindexSOWSidebarInfo: 1;
$zindexSOWSidebarPresets: 2;
$zindexSOWPresetLabel: 1;
$zindexSOWOverviewScrollScrubber: 1;
$zindexSOWOverviewScrollClipping: 2;
$zindexMediaInspirationLoader: 5;
$zindexMediaTagsContainer: 1;
$zindexMediaImageCardDropdownButton: 2;
$zindexSkipToMainContentButton: 6;
$zindexHIPsRoomCategoriesList: 1;
$zindexGuideFloorPlanCTA: 3;
$zindexMediaLoading: 1;
$zindexMediaActions: 1;
$zindexSkeleton: 2;
$zindexBlogSkeleton: 1;
$zindexBlogPostBackButton: 2;
$zindexMarkdownImageWrapper: 0;
$zindexBlogNewsletterPlaceholder: 2;
$zindexBlogPostMarkdownImage: 1;
$zindexBlogStickyHeader: 2;
$zindexBlogStickyHeaderBG: 1;
$zindexBlogDrawerModal: 5;
$zindexBlogDrawerToggleButton: 3;
$zindexBlogDrawerToggleButtonActive: 6;
$zindexBlogCopyLinkModal: 1302;
$zindexBlogArrowGradient: 1;
$zindexBlogHeroImage: 3;
$zindexBlogMediaSelector: 2;
$zindexBlogHeroImageGradient: 2;
$zindexBlogHeroImageTag: 3;
$zindexBlogHeroImageContent: 3;
$zindexBlogGuideSections: 0;
$zindexBlogGuideItemOverlay: 2;
$zindexBlogGuideItemContent: 3;
$zindexBlogHeaderFixedContainer: 4;
$zindexBlogHeaderMainWrapper: 3;
$zindexBlogHeaderContentWrapper: 2;
$zindexBlogHeaderContentSearch: 1;
$zindexBlogHeaderContentSearchOpen: 6;
$zindexBlogHeaderContentSearchBackdrop: 5;
$zindexBlogHeaderProjectGuideContainer: 1;
$zindexBlogHeaderFixedWrapperGlobal: 12;
$zindexBlogSearchPopup: 11;
$zindexBlogSearchPopupContent: 10;
$zindexRoomPageNavigationTabLink: 2;
$zindexFloorPlanHistoryButtonWrapper: 1;
$zindexMediaDragSelectArea: 15;
$zindexMediaActionsHeader: 3;
$zindexImageModalHeader: 1;
$zindexHomeHeader: 2;
$zindexHomeElement: 1;
$zindexFirstLevel: 1;
$zindexSecondLevel: 2;
$zindexThirdLevel: 3;
$zindexInfinite: 20000;
$zindexTryItOutHeader: 25;
$zindexFloorMaterialLabelRemovable: 2;
$zindexFloormaterialLabelUnRemovable: 1;
