@import "src/styles/mixins";

.inputHelper {
  margin: 0;
  color: var(--color-text-secondary);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  padding: 3px 14px 0;
  background: none !important;
  white-space: pre-line;

  &.error {
    color: var(--color-error-main);
  }
}

.inputWrapper {
  --input-background-color: var(--color-white);
  --background-color: none;
  --label-background-color: transparent;

  background: var(--background-color);
  position: relative;

  &:hover {
    background: var(--background-color);
  }

  label {
    padding-top: 0.6rem;
    background: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.15px;
  }

  .label {
    background-color: var(--label-background-color);
  }
}

.input {
  height: 56px;
  padding: 16px 12px;
  background: var(--input-background-color);
  transition: background 0.3s ease-in-out;
  /* stylelint-disable selector-class-pattern */
  &:not(:global(.Mui-error)):not(:global(.Mui-focused)):hover {
    background: var(--color-grey200);
  }

  input {
    &::placeholder {
      color: var(--color-text-secondary);
      opacity: 1;
    }
  }
  /* stylelint-enable selector-class-pattern */
}

.textareaWrapper {
  --background-color: var(--color-white);

  background-color: var(--background-color);
  height: 100%;
  @include true-hover {
    background-color: var(--background-color);
  }

  .textarea {
    height: var(--textarea-height, 100%);
    padding: 15px 0;
    background: none;
    transition: background 0.3s ease-in-out;
    /* stylelint-disable selector-class-pattern */
    &:not(:global(.Mui-error)):not(:global(.Mui-focused)):hover {
      background: var(--background-color);
    }

    /* stylelint-enable selector-class-pattern */

    textarea {
      padding: 0 12px;
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;

      // Note(Andrei): Reassigning styles with important because of
      // precalculated styles params on textarea.
      // This trick allows textarea to be responsive
      height: 100% !important;
      overflow: auto !important;

      &::placeholder {
        color: var(--color-text-secondary);
        opacity: 1;
      }
    }
  }
}
