.powered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 38px;

  .nook {
    width: 66px;
    height: 40px;
    flex-shrink: 0;
    color: var(--color-main-black);
    opacity: 0.2;
  }

  .title {
    color: var(--color-action-disabled);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  }
}
