@import "src/styles/mixins";

.container {
  display: flex;
  align-items: center;

  br {
    display: none;
  }
}

.subscribe {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;

  &__link {
    color: var(--main-blue);
    text-decoration: none;
    margin-left: 4px;
  }
}

@include xs-screen {
  .container {
    .breakSpace {
      display: block;
    }
  }
}
