.snackbarWrapper {
  padding: 0;
  background-color: var(--color-snack-bar-background-dark);
  gap: 25px;

  .messageContainer {
    padding: 0;

    .titleText {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.15px;
      text-align: left;
      color: var(--color-white);
      padding: 0;
      margin: 0;
    }
  }
}
